// Global styling for this template
body,
html {
  width: 100%;
  height: 100%;
}

body {
  @include serif-font;
}

hr {
  max-width: 80px;
  border-width: 3px;
  border-color: $primary;
}

hr.light {
  border-color: $white;
}

a {
  color: $primary;
  @include transition-all;
  &:hover {
    color: darken($primary, .1);
  }
}

.bg-primary .text-faded a {
  color: fade-out($white, .1);
  text-decoration: underline;
  @include transition-all;
  &:hover {
    color: fade-out($white, .1);
  }
}

#footer, #footer a {
  color: #999;
  @include transition-all;
  &:hover {
    color: #999;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include sans-serif-font;
}

.bg-primary {
  background-color: $primary !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.bg-dark {
  background-color: $gray-900 !important;
}

.text-faded {
  color: fade-out($white, .25);
}

.text-faded-2 {
  color: fade-out($white, .5);
}

.text-muted-2 {
  color: #696969;
}

.text-white {
  color: $white;
}

section {
  padding: 8rem 0;
}

.section-heading {
  margin-top: 0;
}

::-moz-selection {
  color: $white;
  background: $gray-900;
  text-shadow: none;
}

::selection {
  color: $white;
  background: $gray-900;
  text-shadow: none;
}

img::selection {
  color: $white;
  background: transparent;
}

img::-moz-selection {
  color: $white;
  background: transparent;
}

body {
  -webkit-tap-highlight-color: $gray-900;
}
