// Bootstrap overrides for this template
.text-primary {
  color: $primary !important;
}

.btn {
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 300px;
  @include sans-serif-font;
}

.btn-xl {
  padding: 1rem 2rem;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: darken($primary, 5%) !important;
  }
  &:active,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($primary, 0.5) !important;
  }
}
