// Styling for the portfolio section

.icon-dark {
  color: $icon-color !important;
}
.portfolio-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto;
  .portfolio-box-caption {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    color: $white;
    background: fade-out($primary, .4);
    @include transition-all;
    .portfolio-box-caption-content {
      position: absolute;
      top: 50%;
      width: 100%;
      transform: translateY(-50%);
      text-align: center;
      .project-category,
      .project-name {
        padding: 0 15px;
        @include sans-serif-font;
      }
      .project-category {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
      }
      .project-name {
        font-size: 18px;
      }
    }
  }
  &:hover {
    .portfolio-box-caption {
      opacity: 1;
    }
  }
  &:focus {
    outline: none;
  }
  @media (min-width: 768px) {
    .portfolio-box-caption {
      .portfolio-box-caption-content {
        .project-category {
          font-size: 16px;
        }
        .project-name {
          font-size: 22px;
        }
      }
    }
  }
}

.max-opacity {
  opacity: 1 !important;
}